import React, {useContext, useEffect, useState} from 'react'
import useGet from '../hooks/useGet'
import LazyLoadingContext from './LazyLoadingContext'

const PoweredByContext = React.createContext({
    isPoweredBy: false
})

export const PoweredByContextProvider = (props) => {

    const [domainsAndTenantsUrl, setDomainsAndTenantsUrl] = useState(null)
    const [tenantLookupUrl, setTenantLookupUrl] = useState(null)
    const [tenantID, setTenantID] = useState('')
    const [options, setOptions] = useState({
        isPoweredBy: '',
        siteReady: false,
        siteChecking: true
    })
    const [tenantIdReady, setTenantIdReady] = useState(false)
    const [tenantConfigReady, setTenantConfigReady] = useState(false)

    const lazyLoadingCtx = useContext(LazyLoadingContext)

    const {returnData: domainsAndTenants} = useGet(domainsAndTenantsUrl)
    const {returnData: tenantConfig} = useGet(tenantLookupUrl)

    useEffect(() => {
        if (domainsAndTenants !== false && domainsAndTenants !== '') {
            lazyLoadingCtx.updateGlobalCheck('domainsAndTenants', true)
        }
    }, [domainsAndTenants])

    useEffect(() => {
        if (tenantConfig !== false && tenantConfig !== '') {
            lazyLoadingCtx.updateGlobalCheck('tenantConfig', true)
        }
    }, [tenantConfig])

    useEffect(() => {
        if (sites.indexOf(window.location.hostname) > -1 && queryParams.get('tenantID') === null) {
            setOptions({
                ...options,
                siteReady: true,
                siteChecking: false
            })
            lazyLoadingCtx.updateGlobalCheck('domainsAndTenants', true)
            lazyLoadingCtx.updateGlobalCheck('tenantConfig', true)
        } else {
            setOptions({...options})
            setDomainsAndTenantsUrl('/api/poweredbyshy/domains-and-tenants')
        }
    }, [])

    useEffect(() => {
        if (domainsAndTenants.domains && domainsAndTenants.tenantIds) {
            setTenantID(getTenantID())
            setTenantIdReady(true)
        }
    }, [domainsAndTenants])

    useEffect(() => {
        if (tenantID !== '') {
            setTenantLookupUrl(`/api/poweredbyshy/tenant-by-id?tenantId=${tenantID}`)
        }
    }, [tenantID])

    useEffect(() => {
        if (tenantConfig !== '' && tenantConfig !== false) {
            setTenantConfigReady(true)
            setOptions({
                ...options,
                isPoweredBy: tenantID !== '',
                tenantID: tenantID,
                tenantUrl: getTenantUrl(tenantID),
                applyTheme: applyTheme,
                ...tenantConfig
            })
        }
    }, [tenantConfig])

    useEffect(() => {
        if (tenantIdReady && tenantID === '') {
            setOptions({
                ...options,
                siteReady: true,
                siteChecking: false
            })
        } else if (tenantIdReady && tenantConfigReady && tenantID !== '') {
            setOptions({
                ...options,
                siteReady: true,
                siteChecking: false
            })
        }
    }, [tenantIdReady, tenantConfigReady, tenantID])

    const queryParams = new URLSearchParams(window.location.search)

    // Which hostnames should PBS not run on?
    const sites = [
        'localhost',
        'witty-bay-072957d03.1.azurestaticapps.net',
        'purple-mud-0efcaf403.1.azurestaticapps.net/',
        'shyaviation.com',
        'www.shyaviation.com',
        'booking.shyaviation.com',
        'shy-static-dev.azurewebsites.net',
        'front.shyaviation.com'
    ]

    // Get the tenant ID from the hostname
    const getTenantID = () => {
        const hostname = window.location.hostname.split('.')
        let id = ''

        if (queryParams.get('tenantID') !== null) {
            // URL param tenant ID
            id = queryParams.get('tenantID')
        } else if (domainsAndTenants.domains[window.location.hostname] !== undefined) {
            // Domain tenant ID
            id = domainsAndTenants.domains[window.location.hostname]
        } else if (domainsAndTenants.domains[window.location.hostname.replace('www.', '')] !== undefined) {
            // Domain tenant ID
            id = domainsAndTenants.domains[window.location.hostname.replace('www.', '')]
        } else if (hostname.length > 1 && sites.indexOf(window.location.hostname) === -1) {
            // Subdomain tenant ID
            id = hostname[0]
        }

        if (domainsAndTenants.tenantIds[id] !== undefined) {
            id = domainsAndTenants.tenantIds[id]
        }

        return id
    }

    // Get the tenant URL from the id
    const getTenantUrl = (tenantID) => {
        let id
        let url

        Object.keys(domainsAndTenants.tenantIds).forEach((tenantId) => {
            if (tenantID === domainsAndTenants.tenantIds[tenantId]) {
                id = tenantId
            }
        })

        Object.keys(domainsAndTenants.domains).forEach((domain) => {
            if (id === domainsAndTenants.domains[domain]) {
                url = domain
            }
        })

        return url
    }

    // Helper function to convert camelCase to kebab-case
    const camelToKebabCase = (str) => {
        return str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
    }

    // Apply the theme to the site
    const applyTheme = (theme) => {
        const root = document.querySelector(':root')
 
        document.querySelector('body').classList.add('is-powered-by')
 
        Object.keys(theme).forEach(item => {
            const kebabCaseItem = camelToKebabCase(item);
            
            root.style.setProperty(`--${kebabCaseItem}`, theme[item]);
        });
    }

    return (
        <PoweredByContext.Provider value={options}>
            {props.children}
        </PoweredByContext.Provider>
    )
}

export default PoweredByContext